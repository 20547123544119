import {Navigate, Outlet, Route, Routes} from "react-router-dom";
import {Main} from "../pages/Main";
import {Rent} from "../pages/Rent";
import {Pay} from "../pages/Pay";
import {Extend} from "../pages/Extend";
import {PayExceededAndExtendTimePathname} from "../common/constants";
import {User} from "../interfaces/admin";
import {Bookings} from "../pages/admin/Bookings";
import {Layout} from "../pages/admin/Layout";
import {Exceeded} from "../pages/Exceeded";
import {Penalty} from "../pages/Penalty";
import {ULockerFlow} from "../pages/admin/ULockerFlow";
import {Spaces} from "../pages/admin/Spaces";

export const Router = () => {
    return (
        <Routes>
            <Route path={'/'} element={<Main/>}/>
            <Route path={'/rent'} element={<Rent/>}/>
            <Route path={'/pay/:bookingID'} element={<Pay/>}/>
            <Route path={'/extend/:bookingID'} element={<Extend/>}/>
            <Route path={`/${PayExceededAndExtendTimePathname}/:bookingID`} element={<Extend/>}/>
            <Route path={'/pay_exceeded/:bookingID'} element={<Exceeded/>}/>
            <Route path={'/pay_penalty/:bookingID'} element={<Penalty/>}/>

            <Route path={'*'} element={<Navigate to={'/'}/>}/>

            // todo: move to monitor
          {/*  <Route element={<ProtectedRoute user={{} as User}/>}>
                <Route path={'/'} element={<Layout/>}>
                    <Route path={'/admin'} element={<Bookings/>}/>
                    <Route path={'/admin/bookings'} element={<Bookings/>}/>
                    <Route path={'/admin/spaces'} element={<Spaces/>}/>
                    <Route path={'/admin/business_flow'} element={<ULockerFlow/>}/>
                </Route>
            </Route>*/}

            {/* <Route path={'/manage_booking'} element={<ManageBooking/>}/>
            <Route path={'/admin_unlock'} element={<Main/>}/>
            <Route path={'/clean_unlock'} element={<Main/>}/>
            <Route path={'/open_selection'} element={<OpenSelection/>}/>

            <Route path={'/admin'} element={<Admin/>}/>
            <Route path={'*'} element={<Navigate to={'/'}/>}/>*/}
        </Routes>
    );
}


const ProtectedRoute = ({user = {} as User, redirectPath = '/login'}) => {
    /*  if (!user) {
          return <Navigate to={redirectPath} replace />;
      }*/

    return <Outlet/>;
};